import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { addSessionToken, isTokenValid } from 'src/util/auth'
import Swal from 'sweetalert2'

const apiUrl = process.env.REACT_APP_API_BASE_URL

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isTokenValid()) {
      navigate('/dashboard')
    } else {
      navigate('/')
    }
  }, [navigate])

  const handleLogin = async (e) => {
    e.preventDefault()

    const data = {
      Email: email,
      Password: password,
    }

    try {
      const response = await axios.post(`${apiUrl}/api/Account/Login`, data)

      if (response?.data?.IsSuccess && response?.data?.Response?.Token) {
        const token = response.data.Response.Token
        addSessionToken(token)

        // Get user data (name, role) from response or set default if needed
        const user = {
          name: `${response.data.Response.FirstName} ${response.data.Response.LastName}`,
          role: response.data.Response.RoleName || 'User',
        }

        // Dispatch user data to Redux store
        dispatch({
          type: 'SET_USER',
          user: user,
        })

        await Swal.fire({
          icon: 'success',
          title: 'Login Successful',
          text: 'You have been logged in successfully.',
          timer: 2000,
          showConfirmButton: false,
        })

        // Redirect to the dashboard
        navigate('/dashboard')
      } else {
        await Swal.fire({
          icon: 'error',
          title: 'Login Failed',
          text: response.data.Message || 'Login failed. Please try again.',
        })
      }
    } catch (error) {
      console.error('Login error:', error)

      await Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred. Please try again.',
      })
    }
  }

  return (
    <div className="min-vh-100 d-flex flex-row align-items-center loginPage">
      <CContainer fluid>
        <CRow className="justify-content-center">
          <CCol xs={11} md={6} lg={4} xl={4} xxl={3} className="loginFrom">
            <CForm onSubmit={handleLogin}>
              <h3 className="mb-0 text-center">Admin Login</h3>
              <p className="text-center mb-3">Sign In to your account</p>
              <CInputGroup className="mb-3">
                <CInputGroupText>
                  <CIcon icon={cilUser} />
                </CInputGroupText>
                <CFormInput
                  placeholder="Email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </CInputGroup>
              <CInputGroup className="mb-4">
                <CInputGroupText>
                  <CIcon icon={cilLockLocked} />
                </CInputGroupText>
                <CFormInput
                  type="password"
                  placeholder="Password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </CInputGroup>
              <CRow>
                <CCol xs={6}>
                  <CButton className="px-4 login_primary" type="submit">
                    Login
                  </CButton>
                </CCol>
                <CCol xs={6} className="text-right">
                  <CButton color="link" className="px-0">
                    {/* Forgot password? */}
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
