import { legacy_createStore as createStore } from 'redux'

const initialState = {
  sidebarShow: true,
  asideShow: false,
  theme: 'dark',
  user: {
    // Default user data
    name: 'Guest',
    role: 'User',
  },
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case 'SET_USER':
      return { ...state, user: rest.user } // Update user data
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
